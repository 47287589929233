import * as React from 'react';
import { PageProps } from 'gatsby';
import { MainTemplate } from '../templates/main/main';
import { SEO } from '../components/seo/seo';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import { QuestionsAnswersList } from '../components/questions-answers-list/questions-answers-list';
import { ALL_FAQ_QUESTIONS } from '../const/faq';

const questionsAndAnswersTranslations = ALL_FAQ_QUESTIONS.map((_, index) => ({
  question: `frequently_asked_questions.data_list.${index}.question`,
  answer: `frequently_asked_questions.data_list.${index}.answer`,
}));

const FrequentlyAskedQuestionsPage = ({ location }: PageProps) => {
  const intl = useIntl();

  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
      questionsAndAnswersTranslations.map(item => ({
        '@type': 'Question',
        name: intl.formatMessage({ id: item.question }),
        acceptedAnswer: {
          '@type': 'Answer',
          text: intl.formatMessage({ id: item.answer }),
        },
      })),
    ],
  };

  return (
    <MainTemplate>
      <SEO
        location={location}
        title={intl.formatMessage({
          id: 'frequently_asked_questions.seo_title',
        })}
        description={intl.formatMessage({
          id: 'frequently_asked_questions.seo_description',
        })}
        schemaMarkup={schemaMarkup}
      />
      <div className="container">
        <h1>
          <FormattedMessage id="frequently_asked_questions.headline" />
        </h1>
        <QuestionsAnswersList data={questionsAndAnswersTranslations} />
      </div>
    </MainTemplate>
  );
};

export default FrequentlyAskedQuestionsPage;
